<template>
  <div>
    <b-card>
      <div slot="header">
        <h3>
          {{ $t('Settings') }}
        </h3>
      </div>
      <vs-tabs>
        <vs-tab :label="$t('General')">
          <tab-content tab-name="general" />
        </vs-tab>
        <vs-tab :label="$t('Database')">
          <tab-content tab-name="database" />
        </vs-tab>
        <vs-tab :label="$t('Mail')">
          <tab-content tab-name="mail" />
        </vs-tab>
        <vs-tab :label="$t('Pusher')">
          <tab-content tab-name="pusher" />
        </vs-tab>
        <vs-tab :label="$t('Login')">
          <login-tab-content />
        </vs-tab>
        <vs-tab :label="$t('Contact')">
          <tab-content tab-name="contact" />
        </vs-tab>
        <vs-tab :label="$t('Site')">
          <tab-content tab-name="site" />
        </vs-tab>
      </vs-tabs>
    </b-card>
  </div>
</template>

<script>
import TabContent from './components/TabContent.vue'
import LoginTabContent from './components/LoginTabContent.vue'

export default {
  name: 'Settings',
  components: { LoginTabContent, TabContent },
  data() {
    return {
      settings: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('settings/getData')
    },
  },
}
</script>

<style scoped>

</style>
