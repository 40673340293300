<template>
  <div>
    <b-row>
      <b-col
        v-for="(setting,index) in localSettings"
        :key="index"
        class="mt-2"
        cols="12"
      >
        <b-card
          :border-variant="setting.switch?'primary':'secondary'"
        >

          <h3 class="mb-2">
            <b-button
              :variant="setting.title !== 'gitlab'?setting.title:'warning'"
              pill
              pressed
              class="btn-icon mr-1"
            >
              <font-awesome-icon
                :icon="['fab', setting.title]"
              />
            </b-button>
            <b>{{ setting.title.charAt(0).toUpperCase() + setting.title.slice(1) }}</b>
          </h3>
          <b-form-checkbox
            v-model="setting.switch"
            switch
            @change="toggleSettingValue(index)"
          />
          <b-collapse v-model="setting.switch">
            <b-row
              class="mt-2"
            >
              <b-col
                lg="6"
                xs="12"
              >
                <vx-textarea
                  v-model="setting.settings[0].value"
                  :label="setting.settings[0].key"
                />
              </b-col>
              <b-col
                lg="6"
                xs="12"
              >
                <vx-textarea
                  v-model="setting.settings[1].value"
                  :label="setting.settings[1].key"
                />
              </b-col>
            </b-row>
          </b-collapse>

        </b-card>
      </b-col>

    </b-row>
    <b-row>
      <b-col
        class="d-flex justify-content-end mt-2"
      >
        <b-button
          variant="gradient-primary"
          @click="save"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'LoginTabContent',
  data() {
    return {
      localSettings: [],
    }
  },
  created() {
    this.localSettings = [...this.$store.getters['settings/loginSettings']]
  },
  methods: {
    save() {
      this.$store.dispatch('settings/saveLoginSettings', { settings: this.localSettings }).then(() => {
        this.localSettings = [...this.$store.getters['settings/loginSettings']]
      })
    },
    toggleSettingValue(val) {
      this.localSettings[val].settings[0].value = ''
      this.localSettings[val].settings[1].value = ''
    },
  },
}
</script>

<style scoped>

</style>
