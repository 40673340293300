<template>
  <div>
    <b-card border-variant="primary">
      <div slot="header">
        <h3 class="mb-2">

          <font-awesome-icon
            :icon="['fas', localSettings.icon]"
          />
          {{ $t(localSettings.title) }}
        </h3>
        <hr>
      </div>
      <b-row>
        <b-col
          v-for="setting in localSettings.settings"
          :key="setting.id"
          class="mt-2"
          vs-justify="flex-end"
          lg="6"
          xs="12"
        >
          <vx-input
            v-if="setting.type === 'string' || setting.type === 'integer'"
            v-model="setting.value"
            :label="$t(setting.key)"
            :type="setting.type === 'string'?'text':'number'"
          />
          <vx-textarea
            v-else-if="setting.type === 'text'"
            v-model="setting.value"
            :label="$t(setting.key)"
          />
          <b-form-checkbox
            v-else-if="setting.type === 'boolean'"
            v-model="setting.value"
            switch
            value="1"
            unchecked-value="0"
          >
            {{ $t( setting.key ) }}
          </b-form-checkbox>
          <div
            v-else-if="setting.type === 'image'"
          >
            <div class="text-center">
              <label>{{ $t(setting.key) }}</label>
            </div>
            <div class="d-flex justify-content-center">
              <vx-upload
                v-model="setting.value"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        class="d-flex justify-content-end mt-2"
      >
        <b-button
          variant="gradient-primary"
          @click="save"
        >
          {{ $t('Save') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'TabContent',
  props: ['tabName'],
  data() {
    return {
    }
  },
  computed: {
    localSettings() {
      return this.$store.getters[`settings/${this.tabName}Settings`]
    },
  },
  methods: {
    save() {
      this.$store.dispatch('settings/saveSettings', { settings: this.localSettings }).catch(errors => {
        this.errors = errors
      })
    },
  },
}
</script>

<style scoped>

</style>
